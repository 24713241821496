'use strict';

module.exports = function (count, chunksize, delay, callback, iterated, finished) {
    var i = 0;
    (function chunk() {
        var end = Math.min(i + chunksize, count);
        for (; i < end; ++i) {
            callback.call(null, i);
        }

        if (i < count) {
            setTimeout(chunk, delay);
        } else {
            finished.call(null);
        }

        iterated.call(null);
    })();
};