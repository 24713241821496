var THREE = require('three');

var images = {
    main: {image: require('./../images/main.png'), randomAmplitude: 0.025},
    technical: {image: require('./../images/technical.png'), randomAmplitude: 0.025},
    medical: {image: require('./../images/medical2.png'), randomAmplitude: 0.025},
    visual: {image: require('./../images/visual.png'), randomAmplitude: 0.025},
    ai: {image: require('./../images/ai.png'), randomAmplitude: 0.025},
    public: {image: require('./../images/public.png'), randomAmplitude: 0.025},
    contact: {image: require('./../images/contact.png'), randomAmplitude: 0.025}
};

function shuffle(a) {
    for (var i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

function load(name, cb) {
    var img = new Image();
    img.src = images[name].image;

    img.onload = function () {
        var logoCanvas = document.createElement('canvas');
        logoCanvas.width = this.width;
        logoCanvas.height = this.height;

        var figureSize = Math.max(logoCanvas.width, logoCanvas.height);
        var logoContext = logoCanvas.getContext('2d');

        logoContext.drawImage(this, 0, 0);
        pixelData = logoContext.getImageData(0, 0, logoCanvas.width, logoCanvas.height).data;

        particleList = {};
        var randomAmplitude = images[name].randomAmplitude;

        for (var i = 0, y = 0; y < this.height; y++) {
            for (var x = 0; x < this.width; x++, i += 4) {

                if (i % 8 === 0)
                    continue;

                var hex = pixelData[i + 2] + (pixelData[i + 1] << 8) + (pixelData[i] << 16);

                if (pixelData[i + 3] > 0) {
                    if (!particleList[hex]) {
                        particleList[hex] = [];
                    }

                    var pos = new THREE.Vector3(
                        (x - figureSize / 2) / figureSize + Math.random() * randomAmplitude,
                        -(y - figureSize / 2 ) / figureSize + Math.random() * randomAmplitude,
                        Math.random() * randomAmplitude
                    );

                    particleList[hex].push({
                        originalPos: pos,
                        pos: pos.clone(),

                        // pos: pos.clone().add(new THREE.Vector3(0, 0, -0.5)),

                        // pos: new THREE.Vector3(pos.x * 0.1, pos.y * 0.1, 0),

                        // pos: new THREE.Vector3(0.0, 0.0, -0.1),

                        // pos: pos.clone().add(
                        //     new THREE.Vector3(0, 0, 0.4)
                        // ),

                        v: new THREE.Vector3(
                            0.5 - Math.random(),
                            0.5 - Math.random(),
                            0.5 - Math.random()
                        ).multiplyScalar(10.0),
                        used: 0
                    });
                }
            }
        }

        Object.keys(particleList).forEach(function (key) {
            shuffle(particleList[key]);

            for (var i = 0; i < particleList[key].length; i++) {
                particleList[key][i].id = i;
            }
        });

        console.log(particleList);
        console.log(Object.keys(particleList).reduce(function (prev, key) {
            prev += particleList[key].length;
            return prev;
        }, 0));

        cb(particleList);
    }
}


module.exports = {
    load: load,
    images: images
};