var THREE = require('three');
var kdTree = require('kd-tree-javascript').kdTree;
var yieldingLoop = require('./yieldingLoop');
var objectLoader = require('./objectLoader');
var sprite = new THREE.TextureLoader().load('images/disc.png');
var objects = {};
var camera, scene, renderer;
var start;
var maxCountdown = 30;
var changeObject = false;
var mouse = {
    x: 0.5,
    y: 0.5,
    click: false,
    countdown: 0
};

var touches = [];

function getCurrentTime() {
    return ( typeof performance === 'undefined' ? Date : performance ).now() / 1000;
}

function updateTouches(evt) {
    touches = evt.touches;
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
}

function distance(a, b) {
    var dx = a.x - b.x;
    var dy = a.y - b.y;
    var dz = a.z - b.z;

    return dx * dx + dy * dy + dz * dz;
}

function mousemove(event) {
    mouse.x = event.clientX;
    mouse.y = event.clientY;
    mouse.countdown = maxCountdown;
}

function calc(group) {
    var o = 0;
    var size = renderer.getSize();
    var points = group.getObjectByName('points');
    var lines = group.getObjectByName('lines');
    var bufferGeometryLinePositions = lines.geometry.attributes.position.array;
    var bufferGeometryLineColors = lines.geometry.attributes.color.array;
    var bufferGeometryPointsPositions = points.geometry.attributes.position.array;
    var particlesList = group.userData.particlesList;

    var coords = [];
    if (mouse.countdown > 0 || mouse.click) {
        if (mouse.click) {
            coords.push([mouse.x, mouse.y, -10.0]);
        } else {
            coords.push([mouse.x, mouse.y, 1.0 * mouse.countdown / maxCountdown]);
        }
    }

    if (changeObject) {
        coords.push([mouse.x, mouse.y, -50.0]);
    }

    for (var i = 0; i < touches.length; i++) {
        var touch = touches[i];
        coords.push([touch.clientX, touch.clientY, 2.0]);
    }

    var plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0).applyMatrix4(group.matrixWorld);

    var raysAttractors = coords.reduce(function (prev, coord) {
        var ray = new THREE.Ray();

        coord[0] = ( coord[0] / size.width ) * 2 - 1;
        coord[1] = -( coord[1] / size.height) * 2 + 1;

        ray.origin.setFromMatrixPosition(camera.matrixWorld);
        ray.direction.set(coord[0], coord[1], 0.5).unproject(camera).sub(ray.origin).normalize();

        var intersectPoint = new THREE.Vector3();

        ray.intersectPlane(plane, intersectPoint);
        intersectPoint = group.worldToLocal(intersectPoint);
        intersectPoint.force = coord[2];

        prev.push(intersectPoint);

        return prev;
    }, []);

    Object.keys(particlesList).forEach(function (key) {
        for (var i = 0; i < particlesList[key].length; i++) {
            var dir, dist;
            var particle = particlesList[key][i];
            var attractors = [[particle.originalPos, 0.001, 10000.0, 0.3]];

            particle.v.multiplyScalar(0.985);

            raysAttractors.forEach(function (rayAttractor) {
                attractors.push([rayAttractor, 0.0, 0.2, rayAttractor.force]);
            });

            attractors.forEach(function (attractor, idx) {
                dir = attractor[0].clone().sub(particle.pos);
                dist = dir.length();

                if (idx === 0) {
                    bufferGeometryLineColors[(i + o) * 4 + 3] = 0.5 - dist * 5.0 - particle.v.length() * 0.02;
                }

                if (dist > attractor[1] && dist < attractor[2]) {
                    dir.divideScalar(dist);
                    dist += 1.0;
                    particle.v.add(dir.clone().multiplyScalar(attractor[3] * dist));
                }
            });

            // particle.v.add(
            //     new THREE.Vector3(
            //         Math.random(), Math.random(), Math.random()
            //     ).multiplyScalar(0.2)
            // );

            particle.pos.add(particle.v.clone().multiplyScalar(0.0001));

            var offset = (i + o) * 3;
            bufferGeometryLinePositions[offset] = bufferGeometryPointsPositions[offset] = particle.pos.x;
            bufferGeometryLinePositions[offset + 1] = bufferGeometryPointsPositions[offset + 1] = particle.pos.y;
            bufferGeometryLinePositions[offset + 2] = bufferGeometryPointsPositions[offset + 2] = particle.pos.z;
        }

        o += particlesList[key].length;
    });

    lines.geometry.attributes.position.needsUpdate = true;
    lines.geometry.attributes.color.needsUpdate = true;
    points.geometry.attributes.position.needsUpdate = true;
}

function prepareObject(data) {
    var maxParticleUsed = 5;
    var colors = [];
    var vertices = [];
    var indices = [];
    var offset = 0;
    var offsets = [];

    var pointsGeometry = new THREE.BufferGeometry();
    var linesGeometry = new THREE.BufferGeometry();

    var particlesList = data;

    Object.keys(particlesList).forEach(function (key) {
        var color = new THREE.Color(parseInt(key));
        offsets.push(offset);

        for (var i = 0; i < particlesList[key].length; i++) {
            var particle = particlesList[key][i];

            vertices.push(particle.pos.x, particle.pos.y, particle.pos.z);
            colors.push(color.r, color.g, color.b, 0.25);
        }

        offset += particlesList[key].length;
    });

    /*
        Points
     */
    pointsGeometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    pointsGeometry.addAttribute('color', new THREE.Float32BufferAttribute(colors, 4));

    var points = new THREE.Points(pointsGeometry, new THREE.PointsMaterial({
        size: 0.003,
        sizeAttenuation: true,
        map: sprite,
        alphaTest: 0.5,
        transparent: true,
        opacity: 0.9,
        vertexColors: THREE.VertexColors
    }));

    points.name = 'points';

    /*
        Lines
     */
    linesGeometry.setIndex(new Array(offset * maxParticleUsed * 2));
    linesGeometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    linesGeometry.addAttribute('color', new THREE.Float32BufferAttribute(colors, 4));
    linesGeometry.setDrawRange(0, 0);

    var lines = new THREE.LineSegments(linesGeometry, new THREE.ShaderMaterial({
            transparent: true,
            uniforms: THREE.UniformsUtils.merge([
                THREE.UniformsLib.points
            ]),
            vertexShader: require('./../shaders/Line.vertex.glsl'),
            fragmentShader: require('./../shaders/Line.fragment.glsl')
        })
    );

    lines.name = 'lines';
    /*
        GROUP
     */
    var group = new THREE.Group();
    group.userData.isParticlesEffects = true;
    group.userData.destroyingStarted = -1000;
    group.userData.particlesList = particlesList;

    group.add(points);
    group.add(lines);

    start = getCurrentTime();

    /*
        Lines connections
     */
    Object.keys(particlesList).forEach(function (key, i) {
        var kdtreeInput = particlesList[key].reduce(function (prev, current) {
            prev.push({
                id: current.id,
                x: current.originalPos.x,
                y: current.originalPos.y,
                z: current.originalPos.z
            });
            return prev;
        }, []);

        tree = new kdTree(kdtreeInput, distance, ['x', 'y', 'z']);

        yieldingLoop(particlesList[key].length - 1, 50, 20, (function (offset, tree, i) {
            var particle = particlesList[key][i];

            if (particle.used < maxParticleUsed) {
                var nearParticles = tree.nearest(particle.originalPos, maxParticleUsed - particle.used + 1,
                    0.03 * 0.03);

                nearParticles.forEach(function (nearParticle) {
                    if (nearParticle.id === particle.id ||
                        nearParticle.used >= maxParticleUsed ||
                        particle.used >= maxParticleUsed) {
                        return;
                    }

                    indices.push(particle.id + offset, nearParticle[0].id + offset);
                    particle.used++;
                    nearParticle[0].used++;

                    if (particle.used >= maxParticleUsed) {
                        tree.remove(particle);
                    }

                    if (nearParticle[0].used >= maxParticleUsed) {
                        tree.remove(nearParticle[0]);
                    }
                });
            }
        }).bind(this, offsets[i], tree), function () {
            linesGeometry.index.array.set(indices);
            linesGeometry.index.needsUpdate = true;

            linesGeometry.setDrawRange(0, indices.length);
        }, function () {

        });
    });

    return group;
}

function removeGroup(group) {
    for (var i = group.children.length - 1; i >= 0; i--) {
        var obj = group.children[i];

        group.remove(obj)

        if (obj.geometry) {
            obj.geometry.dispose();
            obj.geometry = undefined;
        }

        if (obj.material) {
            obj.material.dispose();
            obj.material = undefined;
        }

        obj = undefined;
    }

    scene.remove(group);
}

/*
    RENDERER
 */

renderer = new THREE.WebGLRenderer({antialias: false, powerPreference: "high-performance"});
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0xffffff, 1);

document.getElementById('canvas').appendChild(renderer.domElement);
window.addEventListener('resize', onWindowResize, false);
/*
    CAMERA
 */
camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.05, 2000);

/*
    SCENE
 */
scene = new THREE.Scene();

function render() {
    var t = getCurrentTime();

    camera.position.z = 0.4 + Math.sin(t * 0.1) * 0.015;

    for (var i = scene.children.length - 1; i >= 0; i--) {
        if (scene.children[i].userData.isParticlesEffects) {
            var group = scene.children[i];

            group.rotation.y = Math.sin(t * 0.2) * 0.3;
            group.rotation.x = Math.cos(t * 0.2) * 0.3;
            group.updateMatrix();

            if (group.userData.destroyingStarted) {
                if (t - group.userData.destroyingStarted < 2.0) {
                    group.position.z = (1.0 - Math.cos((t - group.userData.destroyingStarted) * Math.PI / 2.0)) * 0.5;
                    group.rotation.z = 1.0 - Math.cos((t - group.userData.destroyingStarted) * Math.PI / 2.0);

                    if (t - group.userData.destroyingStarted < 1.0) {
                        calc(group);
                    }

                } else {
                    scene.remove(group);
                }
            } else {
                if (t - group.userData.startedTime < 2.0) {
                    var inter = (Math.cos((t - group.userData.startedTime) * Math.PI / 2.0)) * 0.5 + 0.5;

                    group.position.z = -inter;
                    group.children[0].material.opacity = Math.min(1.0 - inter * 1.0, 1.0) * 0.9;
                    group.children[0].material.needsUpdate = true;

                    group.children[1].material.uniforms.opacity.value = Math.min(1.0 - inter * 1.0, 1.0);
                    group.children[1].material.uniforms.needsUpdate = true;
                }

                if (t - group.userData.startedTime > 1.0) {
                    calc(group);
                }
            }
        }
    }

    renderer.render(scene, camera);

    mouse.countdown--;
    changeObject = false;
    requestAnimationFrame(render);
}

render();
document.addEventListener('mousemove', mousemove, false);

document.addEventListener('mouseup', function (evt) {
    mouse.click = false;
}, false);

document.addEventListener('mousedown', function (evt) {
    mouse.click = true;
}, false);

window.addEventListener("touchstart", updateTouches, false);
window.addEventListener("touchend", updateTouches, false);
window.addEventListener("touchmove", updateTouches, false);

function loadIntoCache(id, cb) {
    objectLoader.load(id, function (particleData) {
        objects[id] = prepareObject(particleData);
        cb();
    });
}

function load(id) {
    if (objects[id]) {
        changeObject = true;

        for (var i = 0; i < scene.children.length; i++) {
            var userData = scene.children[i].userData;

            if (userData.isParticlesEffects && !userData.destroyingStarted) {
                userData.destroyingStarted = getCurrentTime();
            }
        }

        Object.keys(objects[id].userData.particlesList).forEach(function (hex) {
            objects[id].userData.particlesList[hex].forEach(function (particle) {
                particle.pos.copy(particle.originalPos);
                particle.v.set(0, 0, 0);
            });
        });

        objects[id].rotation.set(0, 0, 0);
        objects[id].userData.destroyingStarted = null;
        objects[id].userData.startedTime = getCurrentTime();

        scene.add(objects[id]);
        calc(objects[id]);
    } else {
        if (Object.keys(objects).length === 0) {
            // preload
            //Object.keys(objectLoader.images).forEach();
        }

        loadIntoCache(id, load.bind(this, id));
    }
}

module.exports = {
    load: load
};